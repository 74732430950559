/**
 * @file
 * Contains help chat icon.
 */
import React from 'react';

const SupportIcon = (props) => {
  const { color } = props;

  return (
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.02906 4.0673C4.44638 4.0673 4.78468 3.74596 4.78468 3.34957C4.78468 2.95318 4.44638 2.63184 4.02906 2.63184C3.61174 2.63184 3.27344 2.95318 3.27344 3.34957C3.27344 3.74596 3.61174 4.0673 4.02906 4.0673Z"
        fill={color ? color : 'black'}/>
      <path
        d="M6.5525 4.0673C6.96982 4.0673 7.30812 3.74596 7.30812 3.34957C7.30812 2.95318 6.96982 2.63184 6.5525 2.63184C6.13518 2.63184 5.79688 2.95318 5.79688 3.34957C5.79688 3.74596 6.13518 4.0673 6.5525 4.0673Z"
        fill={color ? color : 'black'}/>
      <path
        d="M9.06812 4.0673C9.48544 4.0673 9.82374 3.74596 9.82374 3.34957C9.82374 2.95318 9.48544 2.63184 9.06812 2.63184C8.6508 2.63184 8.3125 2.95318 8.3125 3.34957C8.3125 3.74596 8.6508 4.0673 9.06812 4.0673Z"
        fill={color ? color : 'black'}/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21.6612 8.42332C21.6612 8.81951 21.3227 9.14105 20.9055 9.14105C20.4884 9.14105 20.1499 8.81951 20.1499 8.42332V3.11017C20.1499 2.66613 19.9645 2.24028 19.6331 1.92639C19.3026 1.61155 18.8543 1.43546 18.3868 1.43546H3.27436C2.30011 1.43546 1.51124 2.18477 1.51124 3.11017V17.4648C1.51124 17.9088 1.69662 18.3347 2.02809 18.6486C2.35855 18.9634 2.80688 19.1395 3.27436 19.1395H10.9565C11.3736 19.1395 11.7121 19.461 11.7121 19.8572C11.7121 20.2534 11.3736 20.575 10.9565 20.575H3.27436C2.4059 20.575 1.5727 20.2477 0.959136 19.6639C0.344564 19.0811 0 18.2897 0 17.4648V3.11017C0 1.3924 1.46591 0 3.27436 0H18.3868C19.2553 0 20.0885 0.327285 20.702 0.91104C21.3166 1.49384 21.6612 2.28526 21.6612 3.11017V8.42332Z"
            fill={color ? color : 'black'}/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.9055 5.2627C21.3227 5.2627 21.6612 5.58424 21.6612 5.98043C21.6612 6.37661 21.3227 6.69816 20.9055 6.69816H0.755622C0.338519 6.69816 0 6.37661 0 5.98043C0 5.58424 0.338519 5.2627 0.755622 5.2627H20.9055Z"
            fill={color ? color : 'black'}/>
      <path
        d="M29.0851 10.2959H15.3169C14.0564 10.2959 13.0391 11.3133 13.0391 12.5737V21.685C13.0391 22.9454 14.0564 23.9628 15.3169 23.9628H16.0762V26.2407C16.0762 26.7892 16.6442 27.1865 17.1999 26.9088C17.2151 26.8937 17.2455 26.8937 17.2607 26.8784C21.4375 24.0774 20.5014 24.702 21.4367 24.0843C21.5581 24.0084 21.6948 23.9628 21.8467 23.9628H29.0851C30.3455 23.9628 31.3629 22.9454 31.3629 21.685V12.5737C31.3629 11.3133 30.3455 10.2959 29.0851 10.2959Z"
        fill={color ? color : 'black'}/>
      <path
        d="M21.1094 17.8379V17.5999C21.1094 17.2964 21.1508 17.0308 21.2335 16.8032C21.3232 16.5687 21.437 16.3617 21.575 16.1824C21.713 16.003 21.8544 15.8409 21.9992 15.6961C22.1993 15.496 22.3786 15.3167 22.5373 15.158C22.6959 14.9924 22.7753 14.8027 22.7753 14.5889C22.7753 14.444 22.7408 14.3164 22.6718 14.2061C22.6028 14.0888 22.4993 13.9991 22.3614 13.937C22.2303 13.8681 22.0648 13.8336 21.8647 13.8336C21.6095 13.8336 21.3853 13.9129 21.1921 14.0715C21.0059 14.2233 20.8921 14.4302 20.8507 14.6924L19.4124 14.3302C19.4745 13.9439 19.6159 13.6059 19.8367 13.3162C20.0643 13.0265 20.354 12.7988 20.7058 12.6333C21.0645 12.4677 21.4646 12.385 21.9061 12.385C22.4442 12.385 22.896 12.4919 23.2616 12.7057C23.6341 12.9127 23.9135 13.1817 24.0997 13.5128C24.286 13.837 24.3791 14.1819 24.3791 14.5475C24.3791 14.8096 24.3273 15.0442 24.2239 15.2511C24.1273 15.4512 24.0031 15.6374 23.8514 15.8099C23.6996 15.9754 23.541 16.1375 23.3754 16.2962C23.1616 16.4962 22.9719 16.7066 22.8063 16.9274C22.6408 17.1481 22.558 17.4068 22.558 17.7034V17.8379H21.1094ZM21.068 20.2178V18.6657H22.6201V20.2178H21.068Z"
        fill="white"/>
    </svg>)
};

export default SupportIcon;
